import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import usePlan from "../contexts/planContext";
import { useNavigate } from "react-router-dom";
import { Loading } from "./shared/Loading"; // Assuming you have a Loading component

const Razorpay = ({ history }) => {
  const { plan } = usePlan();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const paymentInitialized = useRef(false);

  const initializePayment = async () => {
    const userId = localStorage.getItem("user");
    const payload = {
      userId,
      plan: plan.title, // assuming plan has a title property
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/kejbuehb/initialize-payment`,
        payload
      );

      const paymentDetails = response.data.data;

      localStorage.setItem("orderId", paymentDetails.orderId); // Save order ID to local storage

      const options = {
        key: paymentDetails.key,
        amount: paymentDetails.amount,
        currency: paymentDetails.currency,
        name: paymentDetails.name,
        description: plan.title,
        order_id: paymentDetails.orderId,
        handler: function (response) {
          // console.log(response);

          // Send payment success details to the server
          axios
            .post(
              `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/v2/payment/update/vijdni`,
              {
                orderId: paymentDetails.orderId,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              }
            )
            .then(() => {
              navigate("/payment-status", { replace: true });
            })
            .catch((error) => {
              // console.error(
              //   "Error sending payment details to the server:",
              //   error
              // );
              navigate("/payment-status?status=fail", { replace: true });
            });
        },
        prefill: {
          name: paymentDetails.name,
          email: paymentDetails.email,
          contact: paymentDetails.number,
        },
        notes: {
          address: "Some address here",
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          escape: false, // Disallow closing the modal
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      rzp1.on("payment.failed", function (response) {
        // console.log(response);
        // Extract details from the failure response
        const errorDetails = {
          code: response.error.code,
          description: response.error.description,
          source: response.error.source,
          step: response.error.step,
          reason: response.error.reason,
          metadata: response.error.metadata,
        };

        // Send payment failure details to the server
        axios
          .post(
            `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/v2/payment/update/vijdni`,
            {
              orderId: response.error.data.error.metadata.order_id,
              razorpay_payment_id:
                response.error.data.error.metadata.payment_id,
              currency: paymentDetails.currency,
              amount: paymentDetails.amount,
              error: errorDetails,
            }
          )
          .then(() => {
            navigate("/payment-status?status=fail", { replace: true });
          })
          .catch((error) => {
            // console.error(
            //   "Error sending payment failure details to the server:",
            //   error
            // );
            navigate("/payment-status?status=fail", { replace: true });
          });
      });

      // Hide the loading indicator once the payment gateway is open
      setLoading(false);
    } catch (error) {
      // console.error("Error initializing payment:", error);
      alert("Failed to initialize payment. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!paymentInitialized.current) {
      paymentInitialized.current = true;
      initializePayment();

      // Prevent back navigation
      window.history.pushState(null, null, window.location.href);
      window.addEventListener("popstate", () => {
        window.history.pushState(null, null, window.location.href);
      });

      return () => {
        window.removeEventListener("popstate", () => {
          window.history.pushState(null, null, window.location.href);
        });
      };
    }
  }, []);

  return (
    <div>
      {loading && (
        <div className="loading-indicator">
          <Loading />
          <p>Loading, please wait...</p>
        </div>
      )}
    </div>
  );
};

export default Razorpay;
