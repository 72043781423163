import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PLANS } from "../../constants/plan";
import useUser from "../../contexts/userContext";
import { stringToNumberForAmount } from "../../helpers/number";
import { Loading } from "../shared/Loading";
import PriorityDetails from "./PriorityDetails";

const PremiumDetails = ({ setProgress }) => {
  const initalDeatils = {
    orderId: "",
    description: "",
    name: "",
    expiredDate: new Date().toString(),
    credit: 0,
    amount: "0.00",
    // plan: PLANS.KING,
    paymentDate: new Date().toString(),
    remainingCredit: 0,
    creditUsed: 0,
  };
  const { user, setUser } = useUser();
  const [displayDeatils, setDisplayDeatils] = useState(initalDeatils);
  const [priorityDetail, setPriorityDetail] = useState({
    budget: "",
    location: "",
    noOfPeople: 1,
    description: "",
  });

  const router = useNavigate();

  // useEffect(() => {
  //   const userId = localStorage.getItem("user");
  //   // console.log("user requirement " +userId);

  //   setProgress(0);
  //   if (userId) {
  //     fetch(`${process.env.REACT_APP_API_IP}/user/plan-details/${userId}/dkkdnie/cjdbcuh`)
  //       .then((res) => res.json())
  //       .then((data) => {
  //         const {
  //           name,
  //           plan,
  //           amount,
  //           purchased_On,
  //           expired_On,
  //           orderId,
  //           credit,
  //           usedCredit,
  //           remainingCredit,
  //         } = data;

  //         setDisplayDeatils({
  //           orderId: orderId || "",
  //           description: "",
  //           name: name || "",
  //           purchasedDate: purchased_On || "",
  //           expiredDate: expired_On || "",
  //           credit: credit || 0,
  //           amount: amount || "0.00",
  //           plan: plan || PLANS.KING,
  //           paymentDate: purchased_On || "",
  //           remainingCredit: remainingCredit || 0,
  //           creditUsed: usedCredit || 0,
  //         });
  //         setProgress(100);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, []);

  useEffect(() => {
    const userId = localStorage.getItem("user");
    let isMounted = true;
    setProgress(0);

    if (userId) {
      fetch(
        `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/plan-details/${userId}/dkkdnie/cjdbcuh`
      )
        .then((res) => res.json())
        .then((data) => {
          if (isMounted) {
            const {
              name,
              plan,
              amount,
              purchased_On,
              expired_On,
              orderId,
              credit,
              usedCredit,
              remainingCredit,
            } = data;

            setDisplayDeatils({
              orderId: orderId || "",
              description: "",
              name: name || "",
              purchasedDate: purchased_On || "",
              expiredDate: expired_On || "",
              credit: credit || 0,
              amount: amount || "0.00",
              plan: plan || PLANS.KING,
              paymentDate: purchased_On || "",
              remainingCredit: remainingCredit || 0,
              creditUsed: usedCredit || 0,
            });
            setProgress(100);
          }
        })
        .catch((err) => console.log(err));
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setPriorityDetail({
      budget: user?.requirement?.budget || "",
      location: user?.requirement?.location || "",
      noOfPeople: user?.requirement?.noOfPeople || 1,
      description: user?.requirement?.description || "",
    });
  }, [user]);
  // if user is not premium then
  if (user && !Boolean(user?.isPremium)) {
    toast.error("Your are not authorized to access this page");
    router("/", { replace: true });
  }

  if (!user || !displayDeatils.orderId) {
    return (
      <>
        <div className="MainContainer">
          <div className="contactedPageMain d-flex align-items-center justify-content-center">
            <div className="my-4" style={{ width: "100%" }}>
              <div
                className="row shadow-sm no-gutters rounded-2 px-2 py-4 d-flex align-items-center justify-content-center "
                id="propertyCard"
                style={{ width: "100%" }}
              >
                <div
                  style={{
                    paddingBlock: "2rem",
                    height: "min-content",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loading />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const submitHandler = async (requirement) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/user-requirement`,
      {
        method: "POST",
        body: JSON.stringify({ id: user.id, requirement }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      toast.success("Priority Update successfully");
    } else {
      toast.error("Could not ablet update your requirment");
    }
  };

  const PriorityDetailsRender = () => {
    const amount = stringToNumberForAmount(displayDeatils?.amount);
    if (amount < PLANS.KING.price) {
      return null;
    }
    if (PriorityDetailsRender != null) {
      return (
        <PriorityDetails
          onSubmit={submitHandler}
          priorityDetail={priorityDetail}
          setPriorityDetail={setPriorityDetail}
        />
      );
    }
  };

  return (
    <div className="MainContainer">
      <div className="contactedPageMain ">
        <section>
          <div id="contactedPropertyHeading">
            <h4 className="mt-5">My Order :</h4>
          </div>
          <div style={{ maxWidth: "100%" }}>
            <div
              className="row shadow-sm no-gutters rounded-2 px-2 py-4"
              id="propertyCard"
            >
              <div className="d-flex align-items-center mb-2 justify-content-between">
                <div className="premiumDetailBox fs-6  detail-description">
                  <div className="mt-2">
                    <span className="opacity-50">Plan:</span>{" "}
                    <span className="opacity-75">{displayDeatils?.plan}</span>
                  </div>
                  {/* <div className="opacity-75">{displayDeatils?.plan}</div> */}
                </div>
                <div className="premiumDetailBox fs-6  detail-name">
                  <div className="mt-2">
                    <span className="opacity-50">Amount:</span>{" "}
                    <span className="opacity-75">
                      ₹{displayDeatils?.amount}
                    </span>
                  </div>
                  {/* <div className="mt-2 opacity-50">
                    Amount: {displayDeatils?.amount}
                  </div> */}
                  {/* <div className="opacity-75">{displayDeatils?.amount}</div> */}
                </div>
              </div>
              <hr />
              <div className="container mb-4">
                <div className="row">
                  <div className="col-6 col-md-4">
                    <div className="premiumDetailBox fs-6">
                      <div className="mt-2 opacity-50">Purchased on:</div>
                      <div className="opacity-75">
                        {displayDeatils?.purchasedDate}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4">
                    <div className="premiumDetailBox fs-6">
                      <div className="mt-2 opacity-50">Expired on:</div>
                      <div className="opacity-75">
                        {displayDeatils?.expiredDate}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4">
                    <div className="premiumDetailBox fs-6">
                      <div className="mt-2 opacity-50">Order ID:</div>
                      <div className="opacity-75">
                        {displayDeatils?.orderId}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <div className="premiumDetailBox fs-6">
                      <div className="mt-2 opacity-50">Credits:</div>
                      <div className="opacity-75">
                        <span style={{ fontWeight: "bold", color: "#0d6efd" }}>
                          {displayDeatils?.credit}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4">
                    <div className="premiumDetailBox fs-6">
                      <div className="mt-2 opacity-50">Used Credit:</div>
                      <div className="opacity-75">
                        <span style={{ fontWeight: "bold", color: "red" }}>
                          {displayDeatils?.creditUsed}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4">
                    <div className="premiumDetailBox fs-6">
                      <div className="mt-2 opacity-50">Remaining Credit:</div>
                      <div className="opacity-75">
                        <span style={{ fontWeight: "bold", color: "green" }}>
                          {displayDeatils?.remainingCredit}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-iterms-center justify-content-end">
                <Link to="/myaccount/contactedproperty">
                  <button className="btn btn-outline-primary rounded-pill ms-auto">
                    View all contacted properties
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* <PriorityDetailsRender /> */}
      </div>
    </div>
  );
};

export default PremiumDetails;
