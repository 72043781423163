import "./ContactedProperty.css";
import { React, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as fasolidHeart,
  faEllipsisVertical,
  faLocationDot,
  faArrowRight,
  faArrowLeft,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart,
  faShareFromSquare,
} from "@fortawesome/free-regular-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import LoginModal from "./Login.js";
import Signup from "./Signup.js";
import Downloads from "./Others/Downloadmodal.jsx";
// import CustomPopup from "./Others/CustomPopup.js";
// import CustomWhatsappPopup from "./Others/CustomWhatsappPopup.js";
import ExpirePopup from "./Others/ExpirePopup.js";
import { useEffect } from "react";
import CustomCarousel from "./Others/CustomCarousel";
const SavedProperty = ({ user }) => {
  const [loginmodal, setLoginmodal] = useState(false);
  const loginModal = () => setLoginmodal(!loginmodal);
  const [signinmodal, setSigninmodal] = useState(false);
  const signinModal = () => setSigninmodal(!signinmodal);
  const [savedProperties, setSavedProperties] = useState([]);
  // const [customPopupmodal, setCustomPopupmodal] = useState(false);
  // const [customWhatsappPopupmodal, setCustomWhatsappPopupmodal] =
  //   useState(false);
  const [expirePopupmodal, setExpirePopupModal] = useState(false);
  const [downloadmodel, setdownloadmodel] = useState(false);
  // const [customePopupData, setCustomPopupData] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleShareClick = async (title, url) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: title,
          url: url,
        });
      } else {
        // Fallback for browsers that don't support the Web Share API
        console.log("Web Share API is not supported.");
        // You can implement a custom popup/modal here
      }
    } catch (error) {
      // console.error("Error sharing:", error);
    }
  };
  // const customPopupModal = () => {
  //   if (customPopupmodal) {
  //     window.location.reload();
  //   }
  //   setCustomPopupmodal(!customPopupmodal);
  // };
  const ExpirePopupModal = () => {
    if (expirePopupmodal) {
      window.location.href = "/premium";
    }
    setExpirePopupModal(!expirePopupmodal);
  };
  const userAgent = navigator.userAgent;
  // alert(userAgent);
  const osType = (() => {
    if (userAgent.includes("Windows")) {
      return "Windows";
    } else if (userAgent.includes("Mac")) {
      return "Mac OS";
    } else if (userAgent.includes("Android")) {
      return "Android";
    } else {
      return "Unknown";
    }
  })();
  const downloadlink = () => {
    // if (osType ==-
    if (osType == "Android") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.codingislife.citynect",
        "_blank",
        "rel=noopener noreferrer"
      );
    } else if (osType == "Mac OS") {
      window.open(
        "https://apps.apple.com/in/app/citynect/id6503302420?platform=iphone",
        "_blank",
        "rel=noopener noreferrer"
      );
    } else {
      setdownloadmodel(true);
    }
  };
  // const customwhatsappPopupModal = (phone) => {
  //   if (customWhatsappPopupmodal) {
  //     if (typeof phone === "string") {
  //       window.location.href = `https://wa.me/${phone}`;
  //     } else {
  //       console.error("Invalid phone number format");
  //     }
  //   }
  //   setCustomWhatsappPopupmodal(!customWhatsappPopupmodal);
  // };
  const sendmessage = async (id, phone) => {
    if (!user) {
      loginModal();
    } else {
      if (user.contacted_property.includes(id)) {
        window.location.href = `https://wa.me/${phone}`;
      } else {
        if (user.propertyCount > 0) {
          // setCustomPopupData(user.propertyCount);
          try {
            const data = {
              id: user.id,
              propertyId: id,
            };
            const response = await fetch(
              `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/dfjkbdhf/vjhdbfuh/contact`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            );
            if (response.ok) {
              // if (!user.isPremium) {
              //   customwhatsappPopupModal(phone);
              // } else {
              //   window.location.href = `https://wa.me/${phone}`;
              // }
              window.location.href = `https://wa.me/${phone}`;
            } else {
              console.error("Failed to submit property:", response.status);
            }
          } catch (error) {
            console.error("Error submitting property:", error);
          }
        } else {
          ExpirePopupModal();
          // alert("Please Buy Premium");
        }
      }
    }
  };
  const toggleBookmark = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/saved`,
        {
          method: "POST",
          body: JSON.stringify({ id: user.id, propertyId: id }), // Convert object to JSON string
          headers: {
            "Content-Type": "application/json", // Set the correct content type for JSON
          },
        }
      );
      if (response.ok) {
        window.location.reload();
      } else {
        console.error("Failed to  saved:", response.status);
      }
    } catch (error) {
      console.error("Error saving property:", error);
    }
  };
  useEffect(() => {
    if (user) {
      setIsLoading(true);

      try {
        fetch(
          `${process.env.REACT_APP_API_IP}/v2/property/chwvcuh/cksbciwb/${user.id}/savedProperties/fjiehdfuegduyf`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Add any additional headers if needed
            },
            // No body in this case
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error("Network response was not ok");
            }
            return res.json();
          })
          .then((responseData) => {
            setSavedProperties(responseData);
            setIsLoading(false);
          })
          .catch((err) => {
            // console.log(err);
            setIsLoading(false);
          });
      } catch (error) {
        // console.log(error);
      }
    }
  }, [user]);

  const [currentPage, setCurrentPage] = useState(1);
  let totalPages = 1;
  let visibleData = [];
  visibleData = savedProperties;
  if (savedProperties && savedProperties.length > 10) {
    totalPages = Math.ceil(savedProperties.length / 10);
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    visibleData = savedProperties.slice(startIndex, endIndex);
  }

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(0, 0);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(0, 0);
  };
  const [showReportButtonForProperty, setShowReportButtonForProperty] =
    useState(null);
  const handleMouseEnter = (propertyId) => {
    setShowReportButtonForProperty(propertyId);
  };
  const contactOwner = async (id, type) => {
    if (!user) {
      signinModal();
    } else if (!user.isVerified) {
      signinModal();
    } else {
      if (user.credit > 0) {
        // setCustomPopupData(user.propertyCount);
        // api to reduce the count for property details
        try {
          const data = {
            userId: user.id,
            propId: id,
            contactType: "call",
            propType: type,
          };
          const response = await fetch(
            `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/dfjkbdhf/vjhdbfuh/contact`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          );
          if (response.ok) {
            // if (!user.isPremium) {
            //   customPopupModal();
            // } else {
            //   window.location.reload();
            // }
            window.location.reload();
          } else {
            console.error("Failed to submit property:", response.status);
          }
        } catch (error) {
          console.error("Error submitting property:", error);
        }
      } else {
        ExpirePopupModal();
        // alert("Please Buy Premium");
      }
    }
  };
  const handleMouseLeave = () => {
    setShowReportButtonForProperty(null);
  };
  const reportProperty = async (reason, id, ownerId) => {
    if (user) {
      try {
        const data = {
          userId: user.id,
          propertyId: id,
          ownerId: ownerId,
          reportTopic: reason,
        };
        const response = await fetch(
          `${process.env.REACT_APP_API_IP}/report/Report-Property`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        if (response.ok) {
          toast.success("Property Reported Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          console.error("Failed to submit property:", response.status);
        }
      } catch (error) {
        console.error("Error submitting property:", error);
      }
    } else {
      toast.error("Login to report Property", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <div className="MainContainer">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <div className="contactedPageMain ">
        <div id="contactedPropertyHeading">
          <h4 className="mt-5">Saved Properties :</h4>
        </div>
        <div className="mb-3 " style={{ maxWidth: "100%" }}>
          {visibleData.length === 0 ? (
            isLoading ? ( // Check if data is loading
              <div className="text-center fs-4">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="text-center fs-2 mb-5">No Result Found</div>
            )
          ) : (
            visibleData.map((property, index) => (
              <div
                className="row shadow-sm no-gutters rounded-2"
                key={index}
                id="propertyCard"
              >
                <div className="col-md-4 p-0">
                  <div id="GenderBox">
                    <span className="">
                      {property.memberedAllowed === "male"
                        ? "Male"
                        : property.memberedAllowed === "female"
                        ? "Female"
                        : property.memberedAllowed === "both"
                        ? "Both"
                        : property.memberedAllowed}
                    </span>
                  </div>
                  <CustomCarousel photoUrls={property.photoUrls} />
                  <div id="iconBox">
                    <span
                      className={`bookmark-button ShareIcons heart ${
                        property.isSaved ? "bookmarked" : ""
                      }`}
                      onClick={() => {
                        toggleBookmark(property.id);
                      }}
                    >
                      {property.isSaved ? (
                        <FontAwesomeIcon icon={fasolidHeart} />
                      ) : (
                        <FontAwesomeIcon icon={faHeart} />
                      )}
                    </span>
                    <span
                      className="ShareIcons"
                      style={{ zIndex: "1" }}
                      onClick={() => {
                        handleShareClick(
                          property.title,
                          `/particular-property/${property.id}`
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faShareFromSquare} />
                    </span>
                  </div>
                </div>
                <div className="col-md-6" id="propertyCard-body">
                  <Link
                    className="text-decoration-none text-dark"
                    to={`/particular-property/${property.id}`}
                  >
                    <div id="card-Heading">{property.title}</div>
                    <div id="card-location" className="row">
                      <div id="" className="col">
                        <FontAwesomeIcon
                          className="me-2 grey"
                          icon={faLocationDot}
                        />{" "}
                        {property.address.area.charAt(0).toUpperCase() +
                          property.address.area.slice(1) +
                          " "}
                        {property.address.city} {property.address.pincode}
                      </div>
                      <div className="LaptopHide col">
                        Type :{" "}
                        {property.furnishedType === "fully-furnished"
                          ? "Fully-Furnished"
                          : property.furnishedType === "unfurnished"
                          ? "Unfurnished"
                          : property.furnishedType === "semi-furnished"
                          ? "Semi-Furnished"
                          : property.furnishedType}
                      </div>
                    </div>
                    <div id="card-Details" className="row">
                      <div id="Details" className="col">
                        <span className="grey">Available For :</span>{" "}
                        {property.memberedAllowed === "male"
                          ? "Male"
                          : property.memberedAllowed === "female"
                          ? "Female"
                          : property.memberedAllowed === "both"
                          ? "Both"
                          : property.memberedAllowed}
                      </div>
                      <div id="Details" className="col">
                        <span className="grey">Property Type :</span>{" "}
                        {property.subtype.length > 12
                          ? `${property.subtype.substring(0, 12)}..`
                          : property.subtype}
                      </div>
                    </div>
                    <div id="card-Details" className="row">
                      <div id="Details" className="col">
                        <span className="grey">Property Size :</span>{" "}
                        {property.bedroom}
                      </div>
                      <div id="Details" className="col">
                        <span className="grey">Status :</span>{" "}
                        <span
                          style={{
                            color: property.isRentedout === 0 ? "green" : "red",
                          }}
                        >
                          {property.isRentedout === 0
                            ? "Available"
                            : "Rented Out"}
                        </span>
                      </div>
                    </div>
                    <div id="emnities" className="mobileAndTab-hide">
                      {property.amenities.slice(0, 8).map((amenity, index) => {
                        const amenityImages = {
                          electricity: "/emenities/Electricity.png",
                          fan: "/emenities/fan.png",
                          lift: "/emenities/lift.png",
                          wifi: "/emenities/wifi.png",
                          desk: "/emenities/desk.png",
                          guard: "/emenities/guard.png",
                          sofa: "/emenities/sofa.png",
                          machine: "/emenities/machine.png",
                          water: "/emenities/Water.png",
                          kitchen: "/emenities/Kitchen.png",
                          AC: "/emenities/AC.png",
                          balcony: "/emenities/balcony.png",
                          CCTV: "/emenities/CCTV.png",
                          stove: "/emenities/stove.png",
                          parking: "/emenities/Parking.png",
                          "2-wheeler-parking": "/emenities/2-wheel-parking.png",
                          tv: "/emenities/T.V.png",
                          "free-wifi": "/emenities/Free wifi.png",
                          cooking: "/emenities/Cooking.png",
                          laundry: "/emenities/Laundry.png",
                          fridge: "/emenities/Fridge.png",
                          "ro-water": "/emenities/RO Water.png",
                          "24*7-water": "/emenities/Water.png",
                          "air-conditioner": "/emenities/AC.png",
                          breakfast: "/emenities/Breakfast.png",
                          gyser: "/emenities/Gyser.png",
                          lunch: "/emenities/Lunch.png",
                          security: "/emenities/Security.png",
                          microwave: "/emenities/Microwave.png",
                          fans: "/emenities/Fen.png",
                          wardrobe: "/emenities/Wardrobe.png",
                          cctv: "/emenities/CCTV.png",
                          "house-keeping": "/emenities/Housekeeping.png",
                        };

                        return (
                          <img
                            key={index}
                            className="amenities-size"
                            src={amenityImages[amenity]}
                            alt="Amenities"
                          />
                        );
                      })}

                      {property.amenities.length > 8 && (
                        <div className="more-properties">
                          + {property.amenities.length - 8}
                        </div>
                      )}
                    </div>
                    <div id="rentBox" className="row">
                      <div className="col p-0 mobileAndTab-hide">
                        <b
                          style={{
                            fontSize: "23px",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          {property.type === "private flat"
                            ? `₹ ${property.totalFlatRent}`
                            : property.sharingType.singleRent
                            ? `₹ ${property.sharingType.singleRent}`
                            : property.sharingType.doubleRent
                            ? `₹ ${property.sharingType.doubleRent}`
                            : property.sharingType.tripleRent
                            ? `₹ ${property.sharingType.tripleRent}`
                            : `₹ ${property.rentEachHead}`}
                        </b>{" "}
                        <span className="grey">/per month</span>
                      </div>
                      <div
                        className="col p-0 mobileAndTab-hide "
                        style={{ color: "rgba(0, 0, 0, 0.7)" }}
                      >
                        {" "}
                        <span className="grey">Deposit </span>:{" "}
                        {property.deposit} Rent{" "}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-2 rounded-4 " id="card-ButtonBox">
                  <div className="flex justify-content-around" id="IconGroup">
                    <span
                      className={`bookmark-button heart ${
                        property.isSaved ? "bookmarked" : ""
                      }`}
                      onClick={() => {
                        toggleBookmark(property.id);
                      }}
                    >
                      {property.isSaved ? (
                        <FontAwesomeIcon icon={fasolidHeart} />
                      ) : (
                        <FontAwesomeIcon icon={faHeart} />
                      )}
                    </span>
                    <span
                      className=""
                      onClick={() => {
                        handleShareClick(
                          property.title,
                          `/particular-property/${property.id}`
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faShareFromSquare} />
                    </span>
                    <span
                      className=""
                      onMouseEnter={() => handleMouseEnter(property.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <FontAwesomeIcon icon={faEllipsisVertical} />
                    </span>
                    {showReportButtonForProperty === property.id && (
                      <div
                        className="reportBlock"
                        onMouseEnter={() => handleMouseEnter(property.id)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <h6 className="text-center">Report Property</h6>
                        <ul>
                          <li
                            role="button"
                            onClick={() => {
                              reportProperty(
                                "Rent Out",
                                property.id,
                                property.ownerId
                              );
                            }}
                          >
                            Rent Out
                          </li>
                          <li
                            role="button"
                            onClick={() => {
                              reportProperty(
                                "Broker's Post",
                                property.id,
                                property.ownerId
                              );
                            }}
                          >
                            Broker's Post
                          </li>
                          <li
                            role="button"
                            onClick={() => {
                              reportProperty(
                                "Asking for Brokerage",
                                property.id,
                                property.ownerId
                              );
                            }}
                          >
                            Asking for Brokerage
                          </li>
                          <li
                            role="button"
                            onClick={() => {
                              reportProperty(
                                "Not Answering",
                                property.id,
                                property.ownerId
                              );
                            }}
                          >
                            Not Answering
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="ownerBox">
                    <span>Owner :</span>
                    <br /> {property.name}
                  </div>
                  <div className="LaptopHide">
                    <b
                      style={{
                        marginLeft: "2px",
                        fontSize: "20px",
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      {property.type === "private flat"
                        ? `₹ ${property.totalFlatRent}`
                        : property.sharingType.singleRent
                        ? `₹ ${property.sharingType.singleRent}`
                        : property.sharingType.doubleRent
                        ? `₹ ${property.sharingType.doubleRent}`
                        : property.sharingType.tripleRent
                        ? `₹ ${property.sharingType.tripleRent}`
                        : `₹ ${property.rentEachHead}`}
                    </b>{" "}
                    <span className="grey" style={{ fontSize: "12px" }}>
                      /per month
                    </span>
                  </div>
                  <Button
                    id="contact-owner"
                    // onClick={() => {
                    //   contactOwner(property.id, property.type);
                    // }}
                    onClick={downloadlink}
                  >
                    {user && property.phone !== "0" ? (
                      <a
                        href={`tel:${property.phone}`}
                        style={{ textDecoration: "none" }}
                      >
                        <FontAwesomeIcon icon={faPhone} /> {property.phone}
                      </a>
                    ) : (
                      "Contact Owner"
                    )}
                  </Button>
                  <Button
                    className=" mobileAndTab-hide"
                    id="send-message"
                    onClick={downloadlink}
                  >
                    Send Message
                  </Button>
                  <Link to={"https://wa.me/9316066832"} className="needHelp">
                    Need Help?
                  </Link>
                </div>
                {/* <CustomWhatsappPopup
                  isOpen={customWhatsappPopupmodal}
                  data={customePopupData}
                  onClose={() => customwhatsappPopupModal(property.phone)}
                /> */}
              </div>
            ))
          )}
          {!isLoading && savedProperties.length > 10 && (
            <div className="paginationBox">
              <Button className="paginationBtn" onClick={goToPreviousPage}>
                {" "}
                <FontAwesomeIcon icon={faArrowLeft} /> Previous{" "}
              </Button>
              <Button className="paginationBtn" onClick={goToNextPage}>
                Next <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          )}
        </div>
        <Downloads
          isOpen={downloadmodel}
          onClose={() => {
            setdownloadmodel(false);
          }}
        />
        <LoginModal isOpen={loginmodal} onClose={loginModal} />
        <Signup isOpen={signinmodal} onClose={signinModal} />
        {/* <CustomPopup
          isOpen={customPopupmodal}
          data={customePopupData}
          onClose={customPopupModal}
        /> */}
        <ExpirePopup isOpen={expirePopupmodal} onClose={ExpirePopupModal} />
      </div>
    </div>
  );
};

export default SavedProperty;
