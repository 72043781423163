// import { Checkout, CheckoutProvider } from "paytm-blink-checkout-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { PAYMENT_STATUS } from "../../../constants/payment";
// import usePayment from "../../../contexts/paymentContext";
import usePlan from "../../../contexts/planContext";
// import { Loading } from "../../shared/Loading";
// import { merchantConfig } from "./mocks/merchant-config";
import { Form, FormGroup } from "react-bootstrap";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

// const USE_EXISTING_CHECKOUT_INSTANCE = "Use existing checkout instance : ";

function ProceedToPayment({user}) {
  const router = useNavigate();
  // inital variable start
  // const userId = localStorage.getItem("user");
  // console.log("payment " +userId);
  // inital variable end

  // context variable and methods start
  // const { setOrderId, setPaymentStatus, getOrderId } = usePayment();
  const { plan } = usePlan();
  // context variable and methods end

  // const getCheckoutJsObj = useCallback(() => {
  //   if (window && window.Paytm && window.Paytm.CheckoutJS) {
  //     return window.Paytm.CheckoutJS;
  //   } else {
  //     console.error(
  //       USE_EXISTING_CHECKOUT_INSTANCE + "Checkout instance not found!"
  //     );
  //   }
  //   return null;
  // }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [Loading , setIsLoading] = useState(true);
  // const [checkoutJsInstance, setCheckoutJsInstance] = useState(null);
  const [error, setError] = useState("")
  const [email, setEmail] = useState("")

  // inital function which update append function handler append to the config
  // const appendMercantConfigHandler = (config) => {
  //   const newConfig = { ...config };
  //   newConfig.handler = {
  //     // TODO: Need to remove this console once it get to production for security and update with api call in new thread
  //     notifyMerchant: function notifyMerchant(eventName, data) {
  //       console.log("errro 1");
  //       // setNotifyMerchantData({ eventName, data });
  //       if (eventName === "APP_CLOSED") {
  //         toast.error(`Payment Failed due to application closed`);
  //         handleCancelOrder(data?.message);
  //         setPaymentStatus(PAYMENT_STATUS.FAILURE);
  //         document.body.style = "";
  //         router(`/payment-status?status=${PAYMENT_STATUS.FAILURE}`, {
  //           replace: true,
  //         });
  //       }
  //       // TODO: uncomment if need to debug
  //       console.log("notify merchant about the payment state", {
  //         eventName,
  //         data,
  //       });
  //     },
  //     transactionStatus: async function transactionStatus(
  //       paymentStatusResponse
  //     ) {
  //       const respose = await fetch(
  //         `${process.env.REACT_APP_API_IP}/api/v1/paymentGateway/paymentstatus-update`,
  //         {
  //           method: "POST", // or 'PUT'
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(paymentStatusResponse),
  //         }
  //       );
  //       const responseData = await respose.json();
  //       const { status } = responseData;
  //       if (window && window.Paytm && window.Paytm.CheckoutJS) {
  //         setIsOpen(false);
  //         document.body.style = "";
  //         window.Paytm.CheckoutJS.close();
  //         if (status === "Success") {
  //           toast.success(`Payment Successfull`);
  //           setPaymentStatus(PAYMENT_STATUS.SUCCESS);
  //         } else {
  //           toast.error(`Payment ${status}`);
  //           setPaymentStatus(PAYMENT_STATUS.FAILURE);
  //         }
  //         router(
  //           `/payment-status?status=${String(status).toLocaleLowerCase() || ""
  //           }`,
  //           {
  //             replace: true,
  //           }
  //         );
  //       }
  //     },
  //   };
  //   return newConfig;
  // };

  // state which require to update before set inital value start
  // const [mConfig, setMConfig] = useState(
  //   appendMercantConfigHandler(merchantConfig)
  // );
  // state which require to update before set inital value end`

  // const getInitalTransactionData = async () => {
  //   // toast.success("getInitalTransactionData calling");
  //   const respose = await fetch(
  //     `${process.env.REACT_APP_API_IP}/api/v1/paymentGateway/start`,
  //     {
  //       method: "POST", // or 'PUT'
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         amount: plan?.price || 0,
  //         customerId: userId,
  //       }),
  //     }
  //   );
  //   const resposeData = await respose.json();
  //   const {
  //     amount,
  //     body: { txnToken },
  //     orderId,
  //   } = resposeData;

  //   setOrderId(orderId);
  //   // default value set if already present value found
  //   // setTransactionStatus(null);
  //   setIsOpen(false);
  //   // setNotifyMerchantData(null);

  //   const checkoutJsInstance = getCheckoutJsObj();

  //   if (checkoutJsInstance) {
  //     setMConfig((previousConfig) => {
  //       const newConfig = { ...previousConfig };
  //       newConfig.data.orderId = orderId;
  //       newConfig.data.token = txnToken;
  //       newConfig.data.amount = amount;
  //       return newConfig;
  //     });

  //     // adding setTimeout because required some 2ms to load paytm script so we dose not get error in console
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 3000);
  //   }

  //   if (checkoutJsInstance && checkoutJsInstance.onLoad) {
  //     checkoutJsInstance.onLoad(() => {
  //       setCheckoutJsInstance(checkoutJsInstance);
  //     });
  //   }
  // };

  // const handleCancelOrder = async (message) => {
  //   const respose = await fetch(
  //     `${process.env.REACT_APP_API_IP
  //     }/api/v1/paymentGateway/cancel-payment/${getOrderId()}`,
  //     {
  //       method: "POST", // or 'PUT'
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         paymentStatus: message,
  //       }),
  //     }
  //   );
  //   const responseData = await respose.json();
  //   console.log(responseData);
  // };

  const cancelOrderHandler = () => {
    router("/premium", { replace: true });
  };

  useEffect(() => {
    setIsLoading(true);
    // getInitalTransactionData();
  }, [plan]);

  const proceedToPaymentHandler = async () => {
    const userId = localStorage.getItem('user')
    const response = await fetch(
      `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/check-email/${userId}`
    );
    const resp = await response.json()
    if (!resp) {
      setIsOpen(true);
      return
    }
    router("/proceed-to-pay", { replace: true })
    // console.log({ amount: plan.price, userId: userId })
  }

  const handleEmailChange = (e) => {
    setError("")
    setEmail(e.target.value)
  }

  const handleEmailSubmit = async () => {
    if (email === "") {
      setError("Email Address is required.")
    } else if (email !== "") {
      const regex = /^[^\s@]+@[^\s@]+\.[a-zA-Z0-9]+$/;
      const checkValid = regex.test(email)
      if (!checkValid) {
        setError("Please Enter valid email address.")
        return
      }
      setError("")

      const response = await fetch(
        `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/adduser-email`,
        {
          method: "POST",
          body: JSON.stringify({ "id": localStorage.getItem('user'), "email": email }), // Convert object to JSON string
          headers: { "Content-Type": "application/json" },
        }
      );
      const resp = await response.json()
      if (resp) {
        setIsOpen(false);
        router("/proceed-to-pay", { replace: true })
        return
      }
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setError("")
    setEmail("")
  }

  const RenderButton = () => {
    return (
      <>
        <div className="checkoutCard-payment-button">
          <button onClick={proceedToPaymentHandler}>Proceed to Payment</button>
        </div>
        <div className="checkoutCard-cancel-button">
          <button onClick={cancelOrderHandler}>Back</button>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`login-modal ${isOpen ? "open" : ""}`}>
        <div className="modal-content">
          <Form>
            <div className="titleBox d-flex flex-column">
              <div className="closeBox">
                <span className="close" onClick={handleClose}>
                  <FontAwesomeIcon icon={faClose} />
                </span>
              </div>
              <div className="d-flex justify-content-center">
                <h4>Enter Your Email</h4>
              </div>
              <span className="subheading">
                To receive your invoice please provide your email.
              </span>
            </div>
            <div className="inputBox">
              <FormGroup>
                <Input
                  type="text"
                  id="emailAddress"
                  required
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => handleEmailChange(e)}
                />
                {error && <div className="text-danger">{error}</div>}
              </FormGroup>
              <button type="button" onClick={handleEmailSubmit} className="mt-4 sm-mt-3">
                <b> Submit Email</b>
              </button>
            </div>
          </Form>
        </div>
      </div>
      <RenderButton />
      {/* <CheckoutProvider
        config={mConfig}
        checkoutJsInstance={checkoutJsInstance}
      >
        {isLoading && (
          <div
            style={{
              paddingBlock: "2rem",
              height: "min-content",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        )}
        {!isLoading && (
          <>
            <RenderButton />
            {isOpen && <Checkout />}
          </>
        )}
      </CheckoutProvider> */}
    </>
  );
}

export default ProceedToPayment;
