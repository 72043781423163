import React, { useState } from "react";
import "./Signup.css";
// import LoginModal from "./Login.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button, Input } from "reactstrap";
import { Form, FormGroup, FormText, Image } from "react-bootstrap";
import { auth, provider } from "../firebase";
import { signInWithPopup } from "firebase/auth";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "./shared/Loading";
const Signup = ({ isOpen, onClose }) => {
  const [googleUser, setGoogleUser] = useState(false);
  // const [loginmodal, setLoginmodal] = useState(false);
  // const loginModal = () => setLoginmodal(!loginmodal);
  useEffect(() => {
    const userId = localStorage.getItem("user");
    // console.log("signup - 1 " + userId);

    // const userIdData = JSON.parse(localStorage.getItem("user"));
    // const userId = userIdData.id;

    if (userId) {
      fetch(`${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          setUser(data);
        })
        .catch((err) => console.log(err));
    }
    if (user.isVerified === 0) {
      setGoogleUser(true);
    }
    // eslint-disable-next-line
  }, [isOpen]);
  const handleGoogleSignIn = async () => {
    try {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const user = result.user;
          setGoogleUser(user);
          const response = await fetch(
            `${process.env.REACT_APP_API_IP}/user/google-signin`,
            {
              method: "POST",
              body: JSON.stringify({
                name: user.displayName,
                email: user.email,
                uid: user.uid,
              }), // Convert object to JSON string
              headers: {
                "Content-Type": "application/json", // Set the correct content type for JSON
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            setUser(data);
            localStorage.setItem("user", data.id);
          } else {
            const error = await response.json();
            // console.log(error.error);
            setError(error.error);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    } catch (error) {
      // console.error(error);
    }
  };
  const [user, setUser] = useState({});
  const [error, setError] = useState();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setOtpSent()
    setError();

  };
  const handlenameChange = (e) => {
    setName(e.target.value);
  };
  const sendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (googleUser) {
      try {
        const userId = localStorage.getItem("user");
        // console.log("signup - 2" +userId);
        // const userIdData = JSON.parse(localStorage.getItem("user"));
        // const userId = userIdData.id;

        const response = await fetch(
          `${process.env.REACT_APP_API_IP}/user/google-user`,
          {
            method: "POST", // Convert object to JSON string},
            body: JSON.stringify({
              name: name,
              number: phoneNumber,
              id: userId,
            }), // Convert object to JSON string
            headers: {
              "Content-Type": "application/json", // Set the correct content type for JSON
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          // console.log(data);
          setUser(data); // Handle the API response here
          setOtpSent("success");
          setLoading(false);
        } else {
          const error = await response.json();
          // console.log(error.error);
          setError(error.error);
        }
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/register`,
          {
            method: "POST", // Convert object to JSON string},
            body: JSON.stringify({
              name: name,
              number: phoneNumber,
              deviceType: "WEB",
            }), // Convert object to JSON string
            headers: {
              "Content-Type": "application/json", // Set the correct content type for JSON
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          // console.log(data);
          setUser(data); // Handle the API response here
          setOtpSent("success");
          setLoading(false);
        } else {
          const error = await response.json();
          // console.log(error.error);
          setError(error.error);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    }
  };
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSignup = async () => {
    // if (user.otp === otp) {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/verify-otp`,
          {
            method: "POST",
            body: JSON.stringify({ otp: otp, number: phoneNumber }), // Convert object to JSON string
            headers: {
              "Content-Type": "application/json", // Set the correct content type for JSON
            },
          }
        );
        if (response.ok) {
          localStorage.setItem("user", user.id);
          setLoading(false);
          window.location.reload();
        } else {
          setError(error);
          setLoading(false);
      setError("Invalid Otp");

        }
      } catch (error) {
        // console.error(error);
        setLoading(false);
        setError("Invalid Otp");
      }
    // } else {
  };
  const resendOtp = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_IP}/v2/user/cjskbcuiw/cwijbsih/resend-otp`,
        {
          method: "POST",
          body: JSON.stringify({ number: phoneNumber }), // Convert object to JSON string
          headers: {
            "Content-Type": "application/json", // Set the correct content type for JSON
          },
        }
      );
      if (response.ok) {
        setOtp("");
        setError(false);
        toast.success("OTP Resent Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Failed to submit property:", response.status);
      }
    } catch (error) {
      console.error("Error submitting property:", error);
    }
  };
  return (
    <div className={`signup-modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <Form>
          <div className="titleBox d-flex flex-column">
            <div className="closeBox">
              <span className="close" onClick={onClose}>
                <FontAwesomeIcon icon={faClose} />
              </span>
            </div>
            <div className="d-flex justify-content-center">
              {googleUser ? (
                <h4>Complete Your Profile !</h4>
              ) : (
                <h4>Create Your Account </h4>
              )}
            </div>
            {googleUser ? (
              <span className="subheading">
                Verify your mobile number to continue your search{" "}
              </span>
            ) : (
              <span className="subheading">
                Hey, Enter Your details to get sign in to your account{" "}
              </span>
            )}
          </div>
          <div className="inputBox">
            <FormGroup>
              <Input
                type="text"
                id="name"
                required
                placeholder="Name"
                value={googleUser ? googleUser.displayName : name}
                onChange={handlenameChange}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="number"
                id="phoneNumber"
                required
                name="phoneNumber"
                placeholder="Phone"
                maxLength={10}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </FormGroup>
            {otpSent === "error" && (
              <FormText className="formtext text-danger">
                Error in sending data
              </FormText>
            )}

            {otpSent === "success" && (
              <FormGroup>
                <Input
                  type="text"
                  id="otp"
                  value={otp}
                  name="otp"
                  placeholder="OTP"
                  onChange={handleOtpChange}
                />
                <FormText className="formtext">
                  <div>
                    Didn't receive code?{" "}
                    <span
                      className="text-primary text-hover"
                      onClick={resendOtp}
                    >
                      Send Again
                    </span>
                  </div>
                </FormText>
              </FormGroup>
            )}
            {error && (
              <FormText className="formtext text-danger">{error}</FormText>
            )}
            {!otpSent && !loading ?  (
              <button type="submit" className="mt-4 sm-mt-3" onClick={sendOtp}>
                <b> Send OTP</b>
              </button>
            ) : otpSent && !loading ? (
              <button className="mt-4" type="button" onClick={handleSignup}>
                Signup
              </button>
            ) : loading ? (
              <div className="mt-3 text-center">
                <Loading />
              </div>
            ) : (
              <></>
            )}
            {/* <div>
              <p className="text-center mt-3">
                Already a member?{"  "}
                <span
                  className="text-primary"
                  role="button"
                  onClick={loginModal}
                >
                  Login now
                </span>
              </p>
            </div> */}
            {/* {!googleUser && (
              <>
                <div
                  className="text-center mt-3"
                  style={{ fontSize: "13px", fontWeight: "500" }}
                >
                  {" "}
                  Or register with{" "}
                </div>
                <div className="d-flex mt-3">
                  <Button
                    className="me-1 brandBtn"
                    onClick={handleGoogleSignIn}
                  >
                    <Image src="./emenities/google.png" />
                    <b> Google</b>
                  </Button>
                </div>
              </>
            )} */}
            <div className="d-flex flex-row-reverse">
              <a
                href="https://wa.me/9316066832"
                className="float-right text-decoration-none text-dark"
                style={{ opacity: ".7", fontSize: "13px" }}
              >
                Need Help?
              </a>
            </div>
          </div>
        </Form>
      </div>
      {/* <LoginModal isOpen={loginmodal} onClose={loginModal} /> */}
    </div>
  );
};

export default Signup;